import { FC, useEffect } from 'react';
import { IAvatar } from 'shared/model';
import { usePlaySound } from 'features/play-sound';
import { Loader } from 'shared/ui';
import Dropdown from 'components/shared/Dropdown';
import { VscChevronDown } from 'react-icons/vsc';
import { Voice, VoiceFilter, voiceStore } from 'entities/tavus/voice';

type Props = {
  avatarGender?: IAvatar['gender'];
  voiceId: string;
  onChange: (voiceId: string) => void;
};

export const TavusChooseVoice: FC<Props> = ({
  avatarGender,
  voiceId,
  onChange
}) => {
  const { filteredVoices, loading, fetchVoices } = voiceStore();

  useEffect(() => {
    fetchVoices();
  }, []);

  const handleChangeVoice = (id: string) => {
    if (id === voiceId) {
      onChange('');
    } else {
      onChange(id);
    }
  };
  const { currentAudio, isPlaying, handlePauseAudio, handlePlayAudio } =
    usePlaySound();

  return (
    <>
      <Dropdown
        disabledButton
        wrapperClassName="ag-relative ag-mb-3"
        icon={<VscChevronDown className={`ag-my-auto ag-transform`} />}
        labelClassName="ag-line-clamp-1"
        label={'Default'}
        buttonClassName="ag-px-4 ag-py-2.5 ag-w-full cursor-not-allowed"
      />
      <div className="ag-mb-6">
        <VoiceFilter />
      </div>
      <div className="ag-h-full ag-flex ag-flex-col ag-gap-y-2 ag-overflow-auto ag-scrollbar-none ag-text-white ag-pb-20">
        {loading ? (
          <div className="ag-flex ag-justify-center">
            <Loader />
          </div>
        ) : filteredVoices?.length ? (
          filteredVoices.map((voice) => (
            <Voice
              key={voice.external_id + voice.gender}
              voice={voice}
              doesGenderMatch={
                avatarGender
                  ? avatarGender.toLowerCase() === voice.gender
                  : true
              }
              selected={voiceId === voice.external_id}
              onPause={handlePauseAudio}
              onPlay={handlePlayAudio}
              currentAudio={currentAudio}
              isPlaying={isPlaying}
              onHandleChange={(voice) => handleChangeVoice(voice.external_id)}
            />
          ))
        ) : (
          <div className="ag-text-base ag-w-full ag-mx-auto ag-p-3 ag-text-center">
            No voices found matching filters
          </div>
        )}
      </div>
    </>
  );
};
