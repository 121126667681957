import { personaStore } from 'entities/persona';
import { Layout } from 'widgets/layout';
import { PersonasList } from 'widgets/personas-list';
import { FilledButton, Loader } from 'shared/ui';
import { useEffect, useState } from 'react';
import { logger, useQueryParams } from 'shared/lib';
import { clsx } from 'clsx';
import { TavusPersonasList } from 'widgets/tavus-personas-list';
import { tavusPersonaStore } from 'entities/tavus/persona';
import { GoPlus } from 'react-icons/go';
import { useAuthStore } from 'stores/useAuthStore';
import { creditStore } from 'entities/credit';
import { CREATE_PERSONA_ROUTE, ENVIRONMENT, featureFlags } from 'shared/model';
import { useNavigate } from 'react-router';

export const Dashboard = () => {
  const nav = useNavigate();
  const { isPersonasLoading, setCreateAgentModal } = personaStore((state) => ({
    isPersonasLoading: state.isPersonasLoading,
    setCreateAgentModal: state.setCreateAgentModal
  }));
  const { productName, isTAvatarsEnabled } = creditStore();
  const { isChatAvailable, toggleFeatureBlockModal } = creditStore((state) => ({
    isChatAvailable: state.isChatAvailable,
    toggleFeatureBlockModal: state.toggleFeatureBlockModal
  }));

  const { isAuth, toggleCreateAccModal } = useAuthStore((state) => ({
    isAuth: state.isAuth,
    toggleCreateAccModal: state.toggleCreateAccModal
  }));

  const tabs = [...(isTAvatarsEnabled() ? ['Avatars'] : []), 'Talking Photos'];
  const handleCreateAgent = () => {
    if (isAuth) {
      if (isChatAvailable) {
        if (isTAvatarsEnabled()) {
          setCreateAgentModal(true);
        } else {
          nav(CREATE_PERSONA_ROUTE);
        }
      } else {
        toggleFeatureBlockModal('agents');
      }
    } else {
      toggleCreateAccModal();
    }
  };

  const [currentTab, setCurrentTab] = useState(tabs[0]);
  const params = useQueryParams();

  const { tavusPersonasLoading, fetchPersonas } = tavusPersonaStore(
    (state) => ({
      fetchPersonas: state.fetchPersonas,
      tavusPersonasLoading: state.tavusPersonasLoading
    })
  );

  useEffect(() => {
    if (isTAvatarsEnabled()) {
      fetchPersonas();
    }
  }, []);

  const [showSurvey, setShowSurvey] = useState<
    'posthog-agents-call' | 'posthog-agents-create' | boolean
  >(false);

  useEffect(() => {
    if (params.get('posthog-agents-call') === 'true')
      setShowSurvey('posthog-agents-call');

    if (params.get('posthog-agents-create') === 'true')
      setShowSurvey('posthog-agents-create');
  }, []);

  useEffect(() => {
    logger.debug('posthog survey is triggered', showSurvey);
  }, [showSurvey]);

  return (
    <Layout>
      {typeof showSurvey === 'string' && (
        <div id={showSurvey} className="absolute size-0"></div>
      )}
      {isPersonasLoading || tavusPersonasLoading ? (
        <div className="ag-w-full ag-h-screen ag-flex ag-items-center ag-justify-center -ag-pt-24">
          <Loader size={96} />
        </div>
      ) : (
        <div className={'ag-w-full'}>
          <div className="ag-flex ag-justify-end ag-pr-20 ag-py-3 ag-border-b ag-border-neutral-200 ag-mb-6">
            <FilledButton
              onClick={() => handleCreateAgent()}
              className={'ag-w-56 ag-h-11 ag-flex-shrink-0 ag-font-semibold'}
            >
              <div className={'ag-mr-2'}>Create Video Agent</div>
              <GoPlus />
            </FilledButton>
          </div>
          <div
            className={
              '!ag-pb-0 ag-px-4 sm:ag-px-6 xl:ag-px-10 ag-flex ag-flex-col ag-w-full'
            }
          >
            <div className="ag-mb-8 ag-gap-8 ag-bg-white">
              <div className="ag-flex ag-flex-1 ag-gap-4 ag-border-b ag-border-neutral-200">
                {tabs.map((tab) => (
                  <div
                    onClick={() => setCurrentTab(tab)}
                    className={clsx(
                      'ag-text-sm ag-font-semibold ag-pt-[1px] ag-px-1 ag-pb-[11px] ag-cursor-pointer',
                      currentTab === tab
                        ? 'ag-text-primary-600 ag-border-b-2 ag-border-primary-700'
                        : 'ag-text-neutral-500'
                    )}
                    key={tab}
                  >
                    {tab}
                  </div>
                ))}
              </div>
            </div>
            {currentTab === 'Avatars' && <TavusPersonasList />}
            {currentTab === 'Talking Photos' && <PersonasList />}
          </div>
        </div>
      )}
    </Layout>
  );
};
