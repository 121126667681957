import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  FilledButton,
  MAIN_ROUTE,
  OutlinedButton,
  showError,
  Loader,
  cn,
  featureFlags
} from 'shared';

import { TavusTopBar } from './TavusTopBar';
import {
  TavusChooseReplica,
  tavusSteps,
  TavusChooseVoice,
  KnowledgeForm,
  PreconversationConfig
} from 'widgets/tavus-create-persona';

import { useFormik } from 'formik';

import { userStore } from 'entities/user';
import {
  ITavusPersonaFormik,
  tavusInitialPersona,
  tavusPersonaStore,
  timerConfigValidationSchema
} from 'entities/tavus/persona';
import { replicaStore } from 'entities/tavus/replica/model/replicaStore';
import { Replica } from 'entities/tavus/replica';
import { TavusPersonaDataForm } from 'widgets/tavus-persona-form';
import StockImagesProvider from 'features/list-stock-images/model/useStockImages';
import { ChooseBackground } from 'widgets/choose-background';

/**
 * CreatePersonaPage component.
 *
 * This component allows users to create a new persona by going through multiple steps.
 * It handles form submission, state management, and conditional rendering based on the current step.
 *
 */

export const TavusCreatePersonaPage = () => {
  const { user } = userStore();

  const [customImageBackgrounds, setCustomImageBackgrounds] = useState<
    string[]
  >([]);
  const [customVideoBackgrounds, setCustomVideoBackgrounds] = useState<
    string[]
  >([]);

  const [currentStep, setCurrentStep] = useState(1);

  const [replica, setReplica] = useState<Replica | undefined>();
  const isPersonaCreating = tavusPersonaStore(
    (state) => state.isPersonaCreating
  );
  const addPersona = tavusPersonaStore((state) => state.addPersona);

  const { replicas, replicasLoading, getReplicaById } = replicaStore(
    (state) => ({
      replicas: state.replicas,
      replicasLoading: state.replicasLoading,
      getReplicaById: state.getReplicaById
    })
  );
  const nav = useNavigate();
  const handleChangeStep = (step: number) => {
    setCurrentStep(step);
  };

  const onSubmit = async (values: ITavusPersonaFormik) => {
    const submitValues = { ...values };
    if (submitValues.name === '') {
      submitValues.name = 'My Video Agent';
    }
    const persona = await addPersona({
      persona_name: submitValues.name,
      context: submitValues.context,
      system_prompt: submitValues.systemPrompt,
      custom_background: submitValues.customBackground
        ? submitValues.customBackground
        : undefined,
      custom_greeting: submitValues.customGreeting,
      default_replica_id: submitValues.defaultReplicaId,
      model: submitValues.model,
      timer_config: submitValues.timerConfig,
      preconversation_config: submitValues.preconversationConfig,
      voice_id: submitValues.voiceId,
      default_language: submitValues.defaultLanguage
    });
    if (persona) nav(MAIN_ROUTE + '?posthog-agents-create=true');
  };

  const formik = useFormik<ITavusPersonaFormik>({
    initialValues: tavusInitialPersona,
    validationSchema: Yup.object({
      name: Yup.string(),
      systemPrompt: Yup.string()
        .required('Please enter description')
        .max(80, 'Please make description shorter'),
      customGreeting: Yup.string().max(500, 'Please make greeting shorter'),
      timerConfig: timerConfigValidationSchema,
      preconversationConfig: Yup.object({
        context: Yup.string(),
        fields: Yup.array().of(
          Yup.object().shape({
            name: Yup.string()
              .matches(/^[A-Za-zА-Яа-я_]+$/, 'No numbers or spaces allowed')
              .notOneOf(['language'], 'This name is reserved')
              .required('Required')
          })
        )
      })
    }),
    onSubmit
  });

  useEffect(() => {
    if (replicas.length) {
      formik.setFieldValue('defaultReplicaId', replicas[0].replica_id);
    }
  }, [replicas]);

  useEffect(() => {
    if (!replicasLoading)
      setReplica(getReplicaById(formik.values.defaultReplicaId));
  }, [formik.values.defaultReplicaId, replicasLoading]);

  useEffect(() => {
    if (!user) {
      showError('You need to login to access this page.');
      nav(MAIN_ROUTE);
    }
  }, [user]);

  const isStepWithAvatarPreview = currentStep === 1 || currentStep === 2;

  return (
    <div className="ag-bg-neutral-900 ag-text-neutral-100 ag-h-screen ag-overflow-auto ag-relative sm:ag-static ag-pt-0 ">
      <TavusTopBar currentStep={currentStep} onStepChange={handleChangeStep} />
      <form
        onSubmit={formik.handleSubmit}
        className="ag-relative  sm:ag-static ag-pb-[63px] sm:ag-pb-0 ag-grid ag-grid-rows-[1fr,auto]"
      >
        <div className="ag-h-full ag-grid ag-grid-rows-[auto,1fr]">
          {isStepWithAvatarPreview ? (
            <div className="ag-size-[12rem] sm:ag-size-56 ag-aspect-square ag-mx-auto ag-overflow-hidden ag-rounded-xl ag-border-none ag-mt-9 sm:ag-mt-10 ag-mb-8">
              {replica ? (
                replica.thumbnail_urls && replica.thumbnail_urls.length ? (
                  <img
                    className="ag-w-full ag-h-full ag-rounded-lg ag-object-cover ag-object-top ag-border-none"
                    src={replica.thumbnail_urls[0]}
                  />
                ) : replica.thumbnail_url ? (
                  <img
                    className="ag-w-full ag-h-full ag-rounded-lg ag-object-cover ag-object-top ag-border-none"
                    src={replica?.thumbnail_url}
                  />
                ) : (
                  <video
                    className="ag-w-full ag-h-full ag-rounded-lg ag-object-cover ag-object-top ag-border-none"
                    src={replica.thumbnail_video_url}
                  />
                )
              ) : (
                <div className="ag-size-full ag-rounded-lg ag-object-cover ag-object-top ag-border-none ag-bg-neutral-100" />
              )}
            </div>
          ) : (
            <div />
          )}
          <div
            className={cn(
              'ag-mx-auto ag-w-full md:ag-px-[calc((100vw-36rem)/2)] ag-px-4 sm:ag-px-6',
              !isStepWithAvatarPreview && 'ag-pt-10 ag-pb-20'
            )}
          >
            {currentStep === 1 && (
              <TavusChooseReplica
                defaultReplicaId={formik.values.defaultReplicaId}
                onChange={(value: string) =>
                  formik.setFieldValue('defaultReplicaId', value)
                }
              />
            )}
            {currentStep === 2 && (
              <StockImagesProvider>
                <ChooseBackground
                  customImageBackgrounds={customImageBackgrounds}
                  setCustomImageBackgrounds={setCustomImageBackgrounds}
                  customVideoBackgrounds={customVideoBackgrounds}
                  setCustomVideoBackgrounds={setCustomVideoBackgrounds}
                  selectedBackground={formik.values.customBackground!}
                  onChange={(color: string) => {
                    formik.setFieldValue('customBackground', color);
                  }}
                />
              </StockImagesProvider>
            )}
            {currentStep === 3 && (
              <div className="ag-w-full ag-grid ag-grid-rows-[auto,1fr] ag-h-full ag-overflow-auto">
                <div className="ag-w-full ag-flex ag-items-center ag-justify-between ag-mb-6 sm:ag-mb-10">
                  <div>Select a voice for your video agent</div>
                </div>

                <div className="ag-grid ag-grid-rows-[auto,1fr] ag-h-full ag-overflow-auto">
                  <TavusChooseVoice
                    voiceId={formik.values.voiceId}
                    onChange={(voiceId) =>
                      formik.setFieldValue('voiceId', voiceId)
                    }
                  />
                </div>
              </div>
            )}
            {currentStep === 4 && <TavusPersonaDataForm formik={formik} />}
            {currentStep === 5 && <KnowledgeForm formik={formik} />}
            {featureFlags.conversationConfig && currentStep === 6 && (
              <PreconversationConfig formik={formik} />
            )}
          </div>
        </div>

        <div className="ag-fixed sm:ag-absolute static ag-bottom-0 ag-left-0 ag-flex ag-items-center ag-w-full ag-border-t ag-px-4 sm:ag-px-6 ag-py-3 ag-justify-end ag-gap-4 ag-bg-neutral-800 ag-border-neutral-700  ">
          {currentStep === 1 && (
            <Link to={MAIN_ROUTE} className="ag-block ag-w-1/2 sm:ag-w-auto">
              <OutlinedButton className="ag-text-sm ag-text-white ag-px-4 ag-py-2 ag-block ag-w-full sm:ag-w-36">
                Cancel
              </OutlinedButton>
            </Link>
          )}
          {currentStep !== 1 && (
            <OutlinedButton
              onClick={() => handleChangeStep(currentStep - 1)}
              className="ag-text-sm ag-text-white ag-px-4 ag-py-2 ag-w-1/2 sm:ag-w-36"
            >
              Back
            </OutlinedButton>
          )}
          {currentStep !== tavusSteps.length && (
            <FilledButton
              type="button"
              className="ag-text-sm ag-text-white ag-px-4 ag-py-2 ag-w-1/2 sm:ag-w-36"
              onClick={async () => {
                if (currentStep === 4) {
                  const errors = await formik.validateForm();

                  if (Object.keys(errors).length > 0) {
                    for (const err in errors) {
                      showError((errors as { [key: string]: string })[err]);
                    }
                    return;
                  }
                }
                handleChangeStep(currentStep + 1);
              }}
            >
              Next
            </FilledButton>
          )}
          {currentStep === tavusSteps.length && (
            <FilledButton
              type="submit"
              disabled={isPersonaCreating}
              onClick={() => {
                if (Object.keys(formik.errors).length > 0) {
                  Object.keys(formik.errors).forEach((e) =>
                    showError((formik.errors as { [key: string]: string })[e])
                  );
                  return;
                }
              }}
              className="ag-text-sm ag-text-white ag-px-4 ag-py-2 ag-w-1/2 sm:ag-w-36 disabled:ag-bg-primary-600"
            >
              {isPersonaCreating ? <Loader isButton size={20} /> : 'Create'}
            </FilledButton>
          )}
        </div>
      </form>
    </div>
  );
};
